import './App.css';
const FullPageIframe = ({ url }) => {
  const iframeStyle = {
    width: '100vw',
    height: '100vh',
    border: 'none', // Optional: Remove the iframe border
  };

  return (
      <iframe
          src={url}
          style={iframeStyle}
          title="UX Hassan"
      />
  );
};
function App() {
  return (
    <div className="App">
      <FullPageIframe url={'https://uxhassan.framer.website'} />
    </div>
  );
}

export default App;
